import React from "react";
import SEO from "../components/seo";
import Harvest from "../scenes/Harvest";


const Index = () => {

  return (
    <>
      <SEO title={"harvest Optimization"} />
      <Harvest/>
    </>
  );
};

export default Index;
