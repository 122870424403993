import React from "react";
import styles from "./Content.module.scss";
import useGetImage from "./useGetImage";

const Content = () => {
  const { graph } = useGetImage();

  return (
    <section>
      <div className={styles.pageDescription}>
        <p>
          HopTechnic®’s Harvest Optimization is a quality standard developed to
          help hop growers identify the best time to harvest their hops within
          their microclimate. Originally developed to manage the quality control
          of the Amarillo® brand as its growing regions expanded, the program
          has been now applied to many other varieties of hops.{" "}
        </p>
        <p>
          Seasonal variation and natural phenomenon have a large affect on the
          way hops mature, and it can be very difficult to rely on historical
          harvest dates or dry matters for harvest readiness. HopTechnic®’s
          harvest optimization focuses more on the aroma of the hops and as
          such, can navigate years when traditional methods are inconclusive.
          Harvest optimization offers the grower and ultimately the brewer, the
          ability to ensure that hops are harvested when they are in an optimal
          window for aroma expression for the season.{" "}
        </p>
        <p>
          Since publicly introduced to the industry in 2020, the program has
          seen a 50-fold increase in year-on-year participation requests and a
          90% return rate as of 2023. The program is currently offered on a
          first-come-first-serve basis and spaces are limited. To enroll in our
          program, a grower should reach out to the lab early in the growing
          season to reserve their space. Once enrolled, the HopTechnic® team
          will start taking field samples in early August as soon as cones begin
          to develop. The lab tests the cones on a frequent basis over the
          course of the harvest season to establish a maturity curve and provide
          forecasting for maturity windows.
        </p>
        <p>
          Here are some additional resources to learn more about our program:
        </p>
        <ul>
          <li>
            Finding the Edges: The Intersection of Art and Science for Hop
            Picking Windows; we were fortunate to be invited to this CLS hosted
            panel at the 2023 CBC in Nashville where we spoke about how our
            program is applied in the industry. The audio and slides are
            available at{" "}
            <a
              href="https://www.hoptalk.live/live-hop-talk"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.hoptalk.live/live-hop-talk
            </a>
          </li>
          <li className={styles.mt15}>
            HopTechnic® Harvest Timing, presented at the HRC Winter Meeting,
            January 2020 <br />
            <p className={styles.aContainer}>
              <a
                href="https://www.hopresearchcouncil.org/page/_ccLib/attachments/about/HRC+2020+poste+Stephanie+Conn.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.hopresearchcouncil.org/page/_ccLib/attachments/about/HRC+2020+poste+Stephanie+Conn.pdf
              </a>
            </p>
          </li>
        </ul>
      </div>
      <div className={styles.textCenter}>
        <img src={graph.publicURL} className={styles.imgWrap} />
      </div>
    </section>
  );
};
export default Content;
