import React from "react";
import Content from "./components/content";
import styles from "./Harvest.module.scss";

const Harvest = () => (
  <div className={styles.wrap}>
    <div className={styles.container}>
      <Content />
    </div>
  </div>
);
export default Harvest;
