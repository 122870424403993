import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      graph: file(
        relativePath: { eq: "images/harvest/HarvestOptimization.jpg" }
      ) {
        publicURL
      }
    }
  `)
  return data
}
